.Home {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  justify-content: flex-start;
  align-items: center;
  background-color: #000000;
  background-image:
    radial-gradient(
      #ffffff,
      #9c9c9cc9
    );  
}

input {
  width: 100%;
  border-radius: 20px;
  margin: 2%;
}

.booking_form {
  width: 85%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  padding-left: 5%;
  padding-right: 5%;
  color: #000000fd;
  padding-top: 3%;
}

h1 {
  margin: 0;
  align-self: center;
}

h3 {
  margin: 0;
}

.booking_text {
  font-size: 1em;
  display: flex;
}

.logo_box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.logo_img {
  width: 80%;
}

.book {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gallery {
  width: 75%;
}

p {
   font-size: 0.5em;
}

label {
  font-size: 0.75em;
}

button {
  margin-top: 10%;
  background-color: #02fa9bfd;
  height: 7vh;
  border-radius: 15px;
  width: 50%;
  align-self: center;
  font-size: 1.5em;
  font-family: 'Bebas Neue', sans-serif;
  color: #000000c9;
}

@media (min-width: 1000px) {
  .Home {
    flex-direction: row;
    align-items: flex-start;
  }

  .logo_img {
    width: 100%;
  }

  .gallery {
    margin-top: 20%;
    width: 75%;
  }
  

  .booking_form {
    width: 50%;
  }

  .book {
    width: 50%;
  }
}