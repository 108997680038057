.Landing {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  background-color: #000000;
  background-image:
    radial-gradient(
      #ffffff,
      #9c9c9cc9
    );    
    scroll-behavior: smooth;
    overflow-y: scroll;
}


.social_links, Link {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav_logo{
  width: 100%;
  margin: 0;
  padding: 0;
}

.hamline {
  width: 50%;
  height: 10px;
  background-color: rgb(0, 0, 0);
  margin-top: 5px;
}

.head {
  width: 100%;
}

.hamburger {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  width: 25%;
}

body {
  width: 100%;
}

.home {
  background-color: #fcfcfc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
}

.home_logo {
  width: 70%;
  pointer-events: none;
}

.photo {
  width: 100%;
  pointer-events: none;
}


.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.link_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
}

.social_media, Link {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

a {
  width: 25%;
  margin: 0;
  text-decoration: none;

}

img {
  width: 50%;
}

.social_button {
  border-radius: 25px;
  background-color: #000000;
  width: 90%;
  display: flex;
  height: 7vh;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  margin-bottom: 5vh;
  box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.655);

}
.social_button:hover {
  box-shadow: 2px 2px 10px 2px rgba(0, 34, 113, 0.655);
}
.social_button::after {
  box-shadow: 2px 2px 10px 2px rgba(0, 34, 113, 0.655);
}
.book_button {
  border-radius: 25px;
  background-color: #000000;
  width: 90%;
  display: flex;
  height: 7vh;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  margin-bottom: 5vh;
  box-shadow: 2px 2px 15px 2px #02fa9bfd;
  border: thick double #02fa9bfd;
}
.book_button:hover {
  box-shadow: 2px 2px 10px 2px rgba(0, 34, 113, 0.655);
}
.book_button::after {
  box-shadow: 2px 2px 10px 2px rgba(0, 34, 113, 0.655);
}

.bio {
  width: 100%;
  font-size: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  padding-top: 5%;
  
}

p {
  width: 90%;
  padding: 1%;
  margin-left: 3%;
  display: none;
}

.top_section {
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin-top: 0;
}

.details {
  background-color: #fffffffd;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.detail_box {
  background-color: #fafafafd;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  box-shadow: 2px 2px 10px 2px #02fa9bfd;

}

.detail_pic {
  margin-top: 5%;
}

.detail_p {
  display: flex;
  font-size: 1em;
}

.package_details {
  font-size: 0.75em;
  margin: 0.25em;
}

/* Minimum width */
@media (min-width: 450px) {
  .App {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    color: black;
    background-color: #000000;
    background-image:
      radial-gradient(
        #ffffff,
        #9c9c9cc9
      );    
    }
  
  .App-header {
    min-height: 10vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .social_links {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  
  
  .nav_logo{
    width: 100%;
  }
  
  .hamline {
    width: 50%;
    height: 10px;
    background-color: rgb(0, 0, 0);
    margin-top: 5px;
  }
  
  .head {
    width: 100%;
  }
  
  .hamburger {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    width: 25%;
  }
  
  body {
    width: 100%;
  }
  
  .home {
    background-color: #fcfcfc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10%;
  }
  
  .home_logo {
    width: 70%;
    pointer-events: none;
  }
  
  .photo {
    width: 100%;
    pointer-events: none;
  }
  
  
  .links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  
  .link_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .social_media {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
  }
  
  a {
    width: 25%;
    margin: 0;
  }
  
  img {
    width: 50%;
  }
  
  .social_button {
    border-radius: 25px;
    background-color: #000000;
    width: 50%;
    display: flex;
    height: 10vh;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    margin-bottom: 5vh;
  }

  .package_details {
    font-size: 0.75em;
    margin: 0.25em;
  }
}
@media (min-width: 1000px) {
  .Landing {
    /* height: 100%; */
    
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    background-color: #000000;
    background-image:
      radial-gradient(
        #ffffff,
        #9c9c9cc9
      );  
    }
  
  
  .social_links {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  
  
  .nav_logo{
    width: 100%;
  }
  
  .hamline {
    width: 50%;
    height: 10px;
    background-color: rgb(0, 0, 0);
    margin-top: 5px;
  }
  
  .head {
    width: 100%;
  }
  
  .hamburger {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    width: 25%;
  }
  
  body {
    width: 100%;
  }
  
  .home {
    background-color: #fcfcfc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10%;
  }
  
  .home_logo {
    width: 100%;
    pointer-events: none;
  }
  
  .photo {
    width: 100%;
    pointer-events: none;
  }
  
  
  
  .links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  
  .link_box {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  
  .social_media {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  a {
    width: 25%;
    margin: 0;
  }
  
  img {
    width: 50%;
  }
  
  .social_button {
    border-radius: 25px;
    background-color: #000000;
    width: 75%;
    display: flex;
    height: 10vh;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    margin-bottom: 5vh;
  }
 
  .book_button {
    width: 75%;
    height: 10vh;
  }

  .bio {
    font-size: 2em;
  }

  p {
    padding: 0%;
    display: flex;
  }

  .top_section {
    height: 100vh;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    padding: 5%;
    margin-top: 0;
    padding-top: 0;
  }

  .details {
    background-color: rgb(255, 255, 255);
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    text-align: center;
    padding-top: 5vh;
  }

  .detail_box {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #fcfcfc;
    padding: 2%;
  }

  .detail_pic {
    width: 75%;
    margin-top: 5%;
  }

  .package_details {
    font-size: 0.75em;
    margin: 0.25em;
  }
}
