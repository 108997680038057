.App {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  background-color: #000000;
  background-image:
    radial-gradient(
      #ffffff,
      #9c9c9cc9
    );    
}

.App-header {
  min-height: 10vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



/* Minimum width */
@media (min-width: 450px) {
  .App {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    color: black;
    background-color: #000000;
    background-image:
      radial-gradient(
        #ffffff,
        #9c9c9cc9
      );    
    }
  
  .App-header {
    min-height: 10vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
}

@media (min-width: 1000px) {
  .App {
    height: 100%;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    color: black;
    background-color: #000000;
    background-image:
      radial-gradient(
        #ffffff,
        #9c9c9cc9
      );  

      font-family: 'Bebas Neue', sans-serif;
    }
  
}

